import React, { Component } from 'react';
import { auth } from '../../../firebase';
import Sidebar from '../../Sidebar';
import Navbar from '../../Navbar';
import { Modal } from 'react-bootstrap';
import './casabautistavital.css';
import 'animate.css';
import Documentos from './Documentos';
import Pagos from './Pagos';
import Construccion from './Construccion';

class CasaBautistaVital extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleSidebar: false,
            porcentaje: 66,
            urlImgModal: '',
            acceso: false,
            vista: 'documentos'
        }
    }

    componentDidMount = () => {
        document.title = 'Garteel Clientes';
        auth.onAuthStateChanged( async (user) => {
            if (user) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ operacion: 'getAcceso', correo: user.email, idProyecto: 38 })
                };
                const response = await fetch('https://clientes.garteel.com/php/DB/inversionistas.php', requestOptions);
                const data = await response.json();
                if (data.error === 0) {
                    if (data.acceso === false) {
                        window.location.href='/';
                    }else{
                        document.title = 'Casa Bautista Vital - Garteel Clientes';
                        this.setState({
                            acceso: true
                        })
                    }
                }else{
                    console.log(data);
                }
            }
        })        
    }

    toggleSidebar = () => {
        this.setState({
            toggleSidebar: !this.state.toggleSidebar
        })
    }

    barraProgreso = (porcentaje) => {
        var gradient = '';
        if (porcentaje < 50) {
            gradient = 'linear-gradient(to right, #2EBF70 ' + porcentaje + '%, white ' + porcentaje + '%)';
        }else{
            gradient = 'linear-gradient(to right, #2EBF70 ' + porcentaje + '%, white ' + (100 - Number(porcentaje)) + '%)';
        }
        return gradient
    }

    porcentaje = (porcentaje) => {
        if (porcentaje < 6) {
            return porcentaje + '%'
        }else{
            return (Number(porcentaje) - 3) + '%'   
        }
    }

    openModalImg = (urlImg) => {
        this.setState({
            showModalImg: true,
            urlImgModal: urlImg
        })
    }

    hideModalImg = () => {
        this.setState({
            showModalImg: false,
            urlImg: ''
        })
    }

    handleDescargarClick = (ruta, nombre) => {
        // Genera un timestamp único para evitar la caché
        const timestamp = new Date().getTime();

        // Agrega el timestamp como parámetro a la URL
        const url = `${ruta}?timestamp=${timestamp}`;

        const username = 'GarteelClientes'; // Reemplaza con tu nombre de usuario
        const password = 'Kmj8CL6z'; // Reemplaza con tu contraseña
    
        // Codifica las credenciales en Base64
        const base64Credentials = btoa(`${username}:${password}`);
    
        // Crea una solicitud de descarga
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
    
        // Configura las credenciales en la solicitud
        xhr.setRequestHeader('Authorization', `Basic ${base64Credentials}`);
    
        // Maneja la respuesta de la solicitud
        xhr.onload = function () {
          if (xhr.status === 200) {
            // La descarga se realizó correctamente, puedes procesar el archivo aquí
            const blob = new Blob([xhr.response], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = nombre; // Nombre del archivo que se descargará
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          } else {
            console.error('Error al descargar el archivo. Código de estado:', xhr.status);
          }
        };
    
        // Envia la solicitud
        xhr.responseType = 'blob';
        xhr.send();
      };

      rutaImagen = (ruta) => {
        const uniqueParam = `?${new Date().getTime()}`;
        return ruta + uniqueParam
      }
    
    render() { 
        return ( 
            <>
            {
                this.state.acceso &&
                <div className='app'>
                    <Sidebar toggledSidebar={this.state.toggleSidebar} toggleSidebar={this.toggleSidebar}/>
                    <main id='main'>
                        <div className="container-fluid h-100 proyecto minaMoran p-0">
                            <Navbar toggleSidebar={this.toggleSidebar}/>
                            <div className="principal" style={{ position: 'relative' }}>
                                <img src={"/img/proyectos/38.jpg"} alt="imgProyecto" style={{ height: '100%', width: '100%' }}/>
                                <h1 className='text-center m-0 mt-2 mt-sm-3 mt-md-4 mt-lg-4 mt-xxl-3 tituloProyecto' style={{ color: 'white', textShadow: '0px 0px 25px rgba(0, 0, 0, 1)', fontFamily: 'Julius', position: 'absolute', top: '0', left: '0', right: '0' }}>Casa Bautista Vital</h1>
                                <div className="descripcion px-3" style={{ position: 'absolute', width: '100%' }}>
                                    <div className='p-1 p-sm-1 p-xl-3 p-xxl-4 caracteristicasGenerales' style={{ color: 'white', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-12 col-xl-6 text-center">
                                                <p className='m-0' style={{ fontFamily: 'Julius' }}><span><i className="bi bi-person-fill"></i></span> Jessica Yaszmín Vital Escamilla</p>
                                            </div>
                                        </div>
                                        <div className="row mt-2 mt-sm-3 mt-md-3 mt-xl-4">
                                            <div className="col-12 text-center">
                                                <p className='m-0 text-white direccion' style={{ fontFamily: 'Julius' }}><span><i className="bi bi-geo-alt-fill"></i></span> Calle Benito Juárez 32A, Col. Centro, C.P. 42180</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>                            
                            </div>
                            <div className="row m-0 mt-4 mt-sm-0 py-4 d-flex justify-content-center" style={{ fontFamily: 'Julius', overflowX: 'hidden' }}>
                                <div className="col-sm-4 text-center">
                                    <p className={'m-0 btnSelector ' + (this.state.vista === 'documentos' ? 'zoomed' : '')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ vista: 'documentos' })}>Documentos</p>
                                </div>
                                <div className="col-sm-4 mt-2 mt-sm-0 text-center">
                                    <p className={'m-0 btnSelector ' + (this.state.vista === 'pagos' ? 'zoomed' : '')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ vista: 'pagos' })}>Pagos</p>
                                </div>
                                <div className="col-sm-4 mt-2 mt-sm-0 text-center">
                                    <p className={'m-0 btnSelector ' + (this.state.vista === 'construccion' ? 'zoomed' : '')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ vista: 'construccion' })}>Construcción</p>
                                </div>
                            </div>
                            {
                                this.state.vista === 'documentos' && <Documentos handleDescargarClick={this.handleDescargarClick}/>
                            }
                            {
                                this.state.vista === 'pagos' && <Pagos handleDescargarClick={this.handleDescargarClick}/>
                            }
                            {
                                this.state.vista === 'construccion' && <Construccion rutaImagen={this.rutaImagen} state={this.state} barraProgreso={this.barraProgreso} porcentaje={this.porcentaje} openModalImg={this.openModalImg}/>

                            }
                            <Modal show={this.state.showModalImg} centered fullscreen onHide={this.hideModalImg}>
                                <Modal.Header closeButton style={{ border: 'none' }} className='bg-dark' closeVariant='white'/>
                                <Modal.Body className='d-flex bg-dark'>
                                    <img src={this.state.urlImgModal} alt="img" className='imgModal'/>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </main>
                </div> 
            }
            </>
         );
    }
}
 
export default CasaBautistaVital;